import React, { useState } from "react";
import { Link } from "react-router-dom";
import { IoMenu } from "react-icons/io5";
import Modal from "../common/MenuModal";
import useWindowSize from "../../hooks/useWindowSize";

function Menu({ className }) {
	const [isOpen, setIsOpen] = useState(false);

	const defaultClasses =
		"md:ml-auto flex flex-wrap items-center text-base justify-center md:w-auto";
	let mergedClasses = `${defaultClasses} ${className}`;

	const { width } = useWindowSize();
	const isMobile = width <= 768;

	const widthStyle = isMobile ? "w-auto" : "w-full";

	if (!isMobile) {
		mergedClasses += "w-full";
	}

	const toggleMenu = () => {
		setIsOpen(!isOpen);
	};

	return (
		<nav className={mergedClasses}>
			{/* Desktop menu (visible on medium+ screens) */}
			<div className="hidden md:flex navbar">
				<Link to="/" className="mr-5 hover:text-red-400">
					Home
				</Link>
				<div className="dropdown mr-5">
					<button className="dropbtn">
						<Link to="/calendar" className="hover:text-red-400">
							Calendar
						</Link>
					</button>
					<div className="dropdown-content">
						<Link to="/calendar" className="hover:text-red-400">
							Calendar
						</Link>
						<Link to="/festivals">Festivals</Link>
					</div>
				</div>

				<div className="dropdown mr-5">
					<button className="dropbtn">
						<Link to="/team" className="hover:text-red-400">
							Vision
						</Link>
					</button>
					<div className="dropdown-content">
						<Link to="/team" className="hover:text-red-400">
							Vision
						</Link>
						<Link to="/team" className="hover:text-red-400">
							Team
						</Link>
					</div>
				</div>

				<Link to="/contact-us" className="mr-5 hover:text-red-400">
					Contact Us
				</Link>
			</div>

			{/* Burger icon (visible on small screens) */}
			<div className={`md:hidden flex items-center ${widthStyle}`}>
				<div className={`flex justify-start ${widthStyle} px-3`}>
					<IoMenu size={40} onClick={toggleMenu} />
				</div>
			</div>

			{/* Mobile Menu (visible only on small screens) */}
			{isOpen && <Modal isOpen={isOpen} closeModal={toggleMenu} />}
		</nav>
	);
}

export default Menu;
